import {
  CallbackFunction,
  Trigger,
} from 'lib/validators/consts/types'

import { i18n } from '@/i18n'

export default (isValidPhone: boolean) => ({
  validator: (rule: any, value: any, callback: CallbackFunction) => {
    if (!isValidPhone) {
      callback(i18n.tc('validator.invalidPhoneNumber'))
    }
    else {
      callback()
    }
  },
  trigger: [Trigger.Blur],
})

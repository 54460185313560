<template>
  <AuthLayout :title="locale.partnerSignUp">
    <AuthPartnerRequestForm />

    <template #after-content>
      <AuthPrivacyPolicy :locale-path="'views.auth.streamerSignin'" />
    </template>

    <template #footer>
      <div class="text-m-regular text-center">
        <router-link :to="{name: 'auth-partner-signin'}">
          <TextLink>{{ locale.partnerSignIn }} </TextLink>
        </router-link>
      </div>
    </template>
  </AuthLayout>
</template>

<script setup>
import AuthPartnerRequestForm from 'Auth/components/AuthPartnerRequestForm'
import AuthPrivacyPolicy from 'Auth/components/AuthPrivacyPolicy'
import TextLink from 'components/buttons/TextLink'
import AuthLayout from 'components/layouts/AuthLayout'
import { useLocale } from 'lib/helpers/useLocale'
import { computed } from 'vue'

const locale = computed(() => ({
  partnerSignUp: useLocale('partnerSignUp'),
  partnerSignIn: useLocale('partnerSignIn'),
}))
</script>

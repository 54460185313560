import email from 'lib/validators/email'
import phoneNumber from 'lib/validators/phoneNumber'
import required from 'lib/validators/required'

export default (isValidPhone: boolean) => ({
  email: [
    required,
    email,
  ],
  name: [
    required,
  ],
  phone: [
    required,
    phoneNumber(isValidPhone),
  ],
  company: [
    required,
  ],
})

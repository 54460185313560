<template>
  <VuePhoneNumberInput
    class="input-phone"
    color="#266FFE"

    :disabled="disabled"
    :value="number"
    :translations="{ phoneNumberLabel: placeholder }"
    :preferred-countries="preferredCountries"

    no-example
    no-validator-state
    :no-use-browser-locale="!!value"
    no-flags
    show-code-on-list

    @update="onUpdate"
    @phone-number-blur="onBlur"
    @phone-number-focused="onFocus"
  />
</template>

<script>
import emitter from 'element-ui/src/mixins/emitter'
import Migrating from 'element-ui/src/mixins/migrating'

import VuePhoneNumberInput from '@/plugins/vue-phone-number-input'

export default {
  components: {
    VuePhoneNumberInput,
  },

  inject: {
    elForm: {
      default: '',
    },
    elFormItem: {
      default: '',
    },
  },

  mixins: [emitter, Migrating],

  props: {
    disabled: VueTypes.bool,
    placeholder: VueTypes.string.def('Телефон'),
    value: VueTypes.string.isRequired,
  },

  data: () => ({
    valueObject: {},
    preferredCountries: ['RU', 'UA'],
    number: '',
  }),

  computed: {
    isValid ({ valueObject }) {
      return valueObject.isValid || !valueObject.phoneNumber
    },
  },

  watch: {
    value (val) {
      this.dispatch('ElFormItem', 'el.form.change', [val])
    },
    valueObject: {
      handler (val) {
        if (!val.formatInternational) {
          this.number = val.phoneNumber || ''
        }
        else {
          this.number = val.formatInternational.replace(`+${val.countryCallingCode} `, '')
        }

        this.$emit('input', val.formatInternational)
      },
      deep: true,
    },
  },

  methods: {
    onUpdate (value) {
      this.valueObject = value
    },

    onBlur () {
      this.$emit('blur')
      this.clearValidate()
      this.validate()

      this.dispatch('ElFormItem', 'el.form.blur', [this.value])
    },

    onFocus () {
      this.$emit('focus')
      this.clearValidate()
      this.validate()
    },

    clearValidate () {
      this.$emit('update:validation-error', '')
    },

    validate () {
      this.$emit('update:is-valid', this.isValid)
    },
  },
}
</script>

<style lang="scss">
.el-form-item.is-error {
  .country-selector__input,
  .input-tel__input {
    border-color: $--color-danger !important;
  }
}
</style>

<style lang="scss" scoped>
$select-country-width: 100px;
$select-country-width-mobile: 90px;

.input-phone {
  ::v-deep {
    .input-tel {
      height: $--input-height;
      min-height: $--input-height;

      &__label {
        display: none;
      }

      &__input {
        box-shadow: none !important;
        height: $--input-height;
        min-height: $--input-height;
        padding-top: 0 !important;
        transition: $--all-transition;
      }

      &.is-disabled .input-tel__input {
        border-color: $--disabled-border-base !important;
        color: $--disabled-color-base;
      }
    }

    .country-selector {
      height: $--input-height;
      min-height: $--input-height;

      &.is-disabled .country-selector__input {
        border-color: $--disabled-border-base !important;
        color: $--disabled-color-base;
      }

      &__label {
        display: none;
      }

      &__input {
        box-shadow: none !important;
        height: $--input-height;
        min-height: $--input-height;
        padding-top: 0 !important;
        transition: $--all-transition;
      }

      &__country-flag {
        top: 50%;
        transform: translateY(-50%);
      }

      &__list {
        width: 320px;
        max-width: none;
        border: $--select-dropdown-border;
        top: calc(100% + 10px);

        &__item {
          height: 32px !important;
          color: $--color-black;

          &__calling-code {
            color: inherit !important;
          }

          &.selected {
            background-color: transparent !important;
            color: $--color-primary;
            font-weight: normal;
          }
        }

        .vue-recycle-scroller__item-view:hover {
          background-color: $--color-primary-light-8;
        }
      }
    }

    .select-country-container {
      flex: 0 0 $select-country-width;
      width: $select-country-width;
      min-width: $select-country-width;
      max-width: $select-country-width;

      @media (max-width: $--large-mobile) {
        flex: 0 0 $select-country-width-mobile;
        width: $select-country-width-mobile;
        min-width: $select-country-width-mobile;
        max-width: $select-country-width-mobile;
      }
    }
  }
}
</style>
